import Tippy from "@tippyjs/react";
import React, { useContext, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import * as yup from "yup";
import { FormattedDatetime } from "../localization";
import schemaFields from "../state/schema";
import { SiteContext } from "../state/SiteContext";
import { UserContext } from "../state/UserContext";
import DashContent from "./DashContent";
import DashHeader from "./DashHeader";
import { DetailsContext, DetailsView } from "./DetailsView";
import Form from "./Form";
import {
  Fieldset,
  Input,
  LabeledWidget,
  SiteSelect,
  ToggleSwitch,
  PlainSelect
} from "./FormWidgets";
import { SectionHeading } from "./Headings";
import Table from "./Table";

const UserForm = ({ user, onSave }) => {
  const { currentUser } = useContext(UserContext);
  const defaultValues = useMemo(() => (user ? { ...user } : { sites: [] }), [
    user,
  ]);
  const intl = useIntl();

  return (
    <Form
      context={UserContext}
      defaultValues={defaultValues}
      onSave={onSave}
      overrideValidationSchema={
        user?.id
          ? () =>
              yup.object().shape({
                first_name: yup.string().required("validation.required"),
                last_name: yup.string().required("validation.required"),
                email: yup
                  .string()
                  .email("validation.email")
                  .required("validation.required")
                  .meta({ label: "E-mail", type: "email" }),
                units: yup
                  .string()
                  .oneOf(["si", "usc"])
                  .meta({ type: "select", values: ["si", "usc"] }),
                password: schemaFields.nullableString,
                password_dacapo: schemaFields.nullableString.oneOf(
                  [yup.ref("password")],
                  "validation.passwords_no_match"
                ),
              })
          : null
      }
      transform={(formData) => {
        if ("password" in formData && !formData.password) {
          delete formData.password;
        }
        delete formData.password_dacapo;
        return { ...defaultValues, ...formData };
      }}
    >
      <LabeledWidget
        widget={<Input />}
        name="username"
        labelId="form.label.username"
        isDisabled={user?.id}
        required={user?.id === undefined}
      />
      <Fieldset inline legendId="form.legend.name">
        <LabeledWidget
          widget={<Input />}
          name="first_name"
          labelId="form.label.first_name"
          required={user?.id === undefined}
        />
        <LabeledWidget
          widget={<Input />}
          name="last_name"
          labelId="form.label.last_name"
          required={user?.id === undefined}
        />
      </Fieldset>
      <LabeledWidget
        name="language"
        labelId="form.label.language"
        required={user?.id === undefined}
        widget={
            <PlainSelect>
              <option></option>
              <option value="en">{intl.formatMessage({id:"form.language_options.english"})}</option>
              <option value="fi">{intl.formatMessage({id:"form.language_options.finnish"})}</option>
              <option value="sv">{intl.formatMessage({id:"form.language_options.swedish"})}</option>
            </PlainSelect>
          }
        />
      <LabeledWidget
        widget={<Input />}
        name="email"
        labelId="form.label.email"
        required={user?.id === undefined}
        type="email"
      />
      <Fieldset legendId="form.legend.access_settings">
        <LabeledWidget
          widget={<Input />}
          name="password"
          labelId="form.label.password"
          required={user?.id === undefined}
          type="password"
        />
        <LabeledWidget
          widget={<Input />}
          name="password_dacapo"
          labelId="form.label.password_dacapo"
          required={user?.id === undefined}
          type="password"
        />
        <div className="flex justify-between items-center my-2">
          <LabeledWidget
            widget={<ToggleSwitch />}
            name="is_admin"
            labelId="form.label.admin_status"
            disabled={user?.id === currentUser?.id}
          />
          <LabeledWidget
            widget={<ToggleSwitch />}
            name="read_only"
            labelId="form.label.read_only"
            disabled={user?.id === currentUser?.id}
          />
        </div>
      </Fieldset>
      <SiteSelect name="sites" />
    </Form>
  );
};

const UserDetails = () => {
  const linkTo = "/users";
  const history = useHistory();
  const intl = useIntl();
  return (
    <DetailsView
      param="user_id"
      context={UserContext}
      getTitle={(creating) => (creating ? intl.formatMessage({id: "user_form_title.add_user"}) : intl.formatMessage({id: "user_form_title.user_properties"}))}
      linkTo={linkTo}
    >
      <DetailsContext.Consumer>
        {({ entity: user, creating }) => (
          <UserForm
            user={user}
            onSave={creating ? () => history.push(linkTo) : () => null}
          />
        )}
      </DetailsContext.Consumer>
    </DetailsView>
  );
};

const UserTable = () => {
  const { url } = useRouteMatch();
  const { users } = useContext(UserContext);
  const { sites } = useContext(SiteContext);

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage id="users_table.header.username" />,
        accessor: "username",
        sortIndicator: "alpha",
        className: "pl-8",
      },
      { Header: <FormattedMessage id="users_table.header.first_name" />, accessor: "first_name", sortIndicator: "alpha" },
      { Header: <FormattedMessage id="users_table.header.last_name" />, accessor: "last_name", sortIndicator: "alpha" },
      { Header: <FormattedMessage id="users_table.header.email" />, accessor: "email", sortIndicator: "alpha" },
      {
        Header: <FormattedMessage id="users_table.header.sites" />,
        accessor: "sites",
        sortType: "basic",
        sortIndicator: "numeric",
        Cell: (cell) => (
          <Tippy
            placement="bottom"
            interactive
            content={
              cell.value?.length > 0 ? (
                <ul
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.75)",
                  }}
                  className="text-white py-1 rounded p-2 max-h-1/2-vh overflow-y-auto"
                >
                  {cell.value.map((site) => (
                    site ? (
                      <li className="my-1" key={site.id}>
                      {site.name}
                    </li>
                    ) : null
                    
                  ))}
                </ul>
              ) : null
            }
          >
            <span>{cell.value?.length}</span>
          </Tippy>
        ),
      },
      {
        Header: <FormattedMessage id="users_table.header.admin" />,
        accessor: "is_admin",
        sortType: "basic",
        Cell: (cell) => (cell.value ? "yes" : "no"),
      },
      {
        Header: <FormattedMessage id="users_table.header.read_only" />,
        accessor: "read_only",
        sortType: "basic",
        Cell: (cell) => (cell.value ? "yes" : "no"),
      },
      {
        Header: <FormattedMessage id="users_table.header.created_by" />,
        accessor: "created_by",
        Cell: (cell) =>
          cell.value ? (
            <span>{`${cell.value.first_name} ${cell.value.last_name}`}</span>
          ) : (
            "-"
          ),
      },
      {
        Header: <FormattedMessage id="users_table.header.last_login" />,
        accessor: "last_login",
        Cell: (cell) =>
          cell.value ? <FormattedDatetime value={cell.value} /> : "",
      },
      {
        Header: <FormattedMessage id="users_table.header.total_logins" />,
        accessor: "login_count",
        sortIndicator: "numeric",
        className: "pr-8",
        sortType: "basic",
      },
    ],
    []
  );
  const data = useMemo(
    () =>
      users.map((user) => ({
        ...user,
        sites: user.sites.map((site_id) =>
          sites.find(({ id }) => id === site_id)
        ),
        created_by: users.find(({ id }) => user.created_by === id),
      })),
    [users, sites]
  );

  return (
    <>
      <DashHeader span>
        <SectionHeading>
          <FormattedMessage id="view.title.users" />
        </SectionHeading>
        <Link className="btn btn-green" to={`${url}/edit/`}>
          <FormattedMessage id="users_management.button.add_user" />  
        </Link>
      </DashHeader>
      <DashContent span noScroll>
        {/*<div className="w-full h-full overflow-hidden bg-white xl:h-auto xl:shadow xl:max-w-screen-xl xl:rounded-lg xl:mt-4 ">*/}
        <div className="w-full h-full overflow-hidden bg-white xl:h-auto xl:w-auto xl:shadow xl:max-w-screen-2xl xl:rounded-lg xl:mt-4 xl:mx-4">
          <Table
            data={data}
            columns={columns}
            sortBy={[{ id: "last_name", desc: false }]}
            linkTo={(obj) => `${url}/edit/${obj.id}`}
          />
        </div>
      </DashContent>

    </>
  );
};

export default function UsersView() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={[`${path}/edit/:user_id`, `${path}/edit/`]}>
        <UserDetails />
      </Route>
      <Route>
        <UserTable />
      </Route>
    </Switch>
  );
}
